.Client-Container{
    position: relative;
    width: 100%;
    height: 300px;
    background-color: rgb(252, 252, 252);
    margin: 0%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    animation: bgFade 3.5s 1;
    figure{
        margin: 0%;
    }
    .Container-Tag{
        position: absolute;
        right: 0px;
        background-color: #91c8f5;
        box-shadow: 3px 3px rgb(211, 211, 211);
        width: 200px;
        top: 10px;
        display: flex;
        justify-content: end;
        h1{
            margin: 0%;
            margin-right: 10px;
            color: black;
        }
    }
    .Clients-Logos{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        .Client{
            animation: logoFadeIn 4s 1;
            opacity: 1;
            figure{
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .Client-Container{
        height: 100%;
        .Clients-Logos{
            margin-top: 50px;
            flex-wrap: wrap;
        }
    }
  }

@keyframes logoFadeIn {
    0%      {opacity: 0;}
    50%     {opacity: 0;}
    100%    {opacity: 1;}
}

@keyframes bgFade {
    0%      {background:rgb(0, 0, 0);}
    50%      {background:rgb(0, 0, 0);}
    100%    {background:rgb(255, 255, 255); opacity: 1;}
}
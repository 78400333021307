.Footer-Container{
    width: 100%;
    height: 100px;
    background-color: #91c8f5;
    display: flex;
    .Footer-Navigation{
        padding-left: 150px;
        .Navigation-Item{
            .Navigation-Link{
                text-decoration: none;
                color: rgb(255, 255, 255);
                font-size: 25px;
            }
            .Navigation-Link:hover{
                color: rgb(0, 0, 0);
                border-bottom: 1px solid rgb(0, 153, 255);
            }
        }
    }
}
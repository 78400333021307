.Experience-Container{
    position: relative;
    z-index: 5;
    min-height: 100vh;
    background-color: white;
    padding-bottom: 30px;
    .Section-Title{
        position: absolute;
        right: 0px;
        background-color: #91c8f5;
        box-shadow: 3px 3px rgb(211, 211, 211);
        width: 200px;
        display: flex;
        justify-content: end;
        h1{
            color: black;
            margin-right: 10px;
        }
    }

    .Experience-Card-Container{
        width: 100%;
        height: 100%;
        padding-top: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .Experience-Card:nth-child(4n+1){
            background-color: #91c8f5;
            border-radius: 0px 25px 25px 0px;
        }
        .Experience-Card:nth-child(4n){
            background-color: #91c8f5;
            border-radius: 25px 0px 0px 25px;
        }
        .Experience-Card:nth-child(2n){
            border-radius: 25px 0px 0px 25px;
        }
        .Experience-Card:nth-child(2n+1){
            border-radius: 0px 25px 25px 0px;
        }
        .Experience-Card{
            flex-basis: 49.5%;
            height: 150px;
            display: flex;
            background-color: white;
            padding-top: 20px;
            padding-bottom: 20px;
            box-shadow: 15px 5px 15px rgb(161, 161, 161);
            margin-top: 10px;
            margin-bottom: 10px;
            figure{
                margin: 0%;
                height: 100%;
                width: 100%;
                flex-basis: 35%;
                margin-left: 10px;
                img{
                    border-radius: 5px;
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
            .Experience-Details{
                display: flex;
                flex-wrap: wrap;
                flex-basis: 65%;
                h1,h2{
                    flex-basis: 100%;
                    text-align: start;
                    margin: 0%;
                    margin-left: 50px;
                }
                h1{
                    font-size: 25px;
                    color: black;
                }
                h2{
                    font-size: 20px;
                }
            }
        }     
    }
}

@media only screen and (max-width: 1200px) {
    .Experience-Container{
        .Experience-Card-Container{
            .Experience-Card{
                flex-basis: 100%; 
                border-radius: 0px 0px 0px 0px !important;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Experience-Container{
        .Experience-Card-Container{
            .Experience-Card{
                flex-wrap: wrap;
                justify-content: center;
                height: 100%;
                border-radius: 0px 0px 0px 0px !important;
                figure{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 100px;
                }
            }
        }
    }
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap");
.Landing-Banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 90vh;
}

.Landing-Banner .Box-Left {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Landing-Banner .Box-Left .Box-Left-Top {
  background-color: #91c8f5;
}

.Landing-Banner .Box-Left .Box-Left-Bottom {
  background-color: #91c8f5;
  z-index: 10;
}

.Landing-Banner .Box-Right {
  width: 60%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Landing-Banner .Box-Right .Box-Right-Top {
  background-color: #ffffff;
}

.Landing-Banner .Box-Right .Box-Right-Bottom {
  background-color: #ffffff;
  z-index: 10;
}

.Landing-Banner .Landing-Title {
  color: black;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Landing-Banner .Landing-Title h1, .Landing-Banner .Landing-Title h2 {
  color: black;
}

.Landing-Banner .Landing-Title h1 {
  font-size: 70px;
  margin-left: 25px;
}

.Landing-Banner .Landing-Title h2 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  font-size: 35px;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .Landing-Banner .Landing-Title {
    color: black;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .Landing-Banner .Landing-Title h1, .Landing-Banner .Landing-Title h2 {
    color: black;
  }
  .Landing-Banner .Landing-Title h1 {
    font-size: 50px;
    margin-left: 25px;
  }
  .Landing-Banner .Landing-Title h2 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    font-size: 35px;
    text-align: center;
  }
}

.Client-Container {
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #fcfcfc;
  margin: 0%;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 50px;
  -webkit-animation: bgFade 3.5s 1;
          animation: bgFade 3.5s 1;
}

.Client-Container figure {
  margin: 0%;
}

.Client-Container .Container-Tag {
  position: absolute;
  right: 0px;
  background-color: #91c8f5;
  -webkit-box-shadow: 3px 3px lightgray;
          box-shadow: 3px 3px lightgray;
  width: 200px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.Client-Container .Container-Tag h1 {
  margin: 0%;
  margin-right: 10px;
  color: black;
}

.Client-Container .Clients-Logos {
  width: 80%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.Client-Container .Clients-Logos .Client {
  -webkit-animation: logoFadeIn 4s 1;
          animation: logoFadeIn 4s 1;
  opacity: 1;
}

.Client-Container .Clients-Logos .Client figure {
  width: 200px;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Client-Container .Clients-Logos .Client figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

@media only screen and (max-width: 400px) {
  .Client-Container {
    height: 100%;
  }
  .Client-Container .Clients-Logos {
    margin-top: 50px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@-webkit-keyframes logoFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes logoFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bgFade {
  0% {
    background: black;
  }
  50% {
    background: black;
  }
  100% {
    background: white;
    opacity: 1;
  }
}

@keyframes bgFade {
  0% {
    background: black;
  }
  50% {
    background: black;
  }
  100% {
    background: white;
    opacity: 1;
  }
}

.Education-Container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: white;
}

.Education-Container .Section-Title-Container {
  position: absolute;
  left: 0px;
  background-color: #91c8f5;
  -webkit-box-shadow: 3px 3px lightgray;
          box-shadow: 3px 3px lightgray;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.Education-Container .Section-Title-Container .Section-Title {
  color: black;
  margin-left: 10px;
}

.Education-Container .Education-Card-Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: 100%;
  padding-top: 100px;
}

.Education-Container .Education-Card-Container .Education-Card {
  width: 350px;
  height: 500px;
  border: 1px solid black;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 50px;
  background-color: white;
  -webkit-transition: background-color .5s ease-in-out;
  transition: background-color .5s ease-in-out;
  -webkit-box-shadow: 15px 15px 20px rgba(88, 88, 88, 0.534);
          box-shadow: 15px 15px 20px rgba(88, 88, 88, 0.534);
}

.Education-Container .Education-Card-Container .Education-Card .School-Link {
  text-decoration: none;
  color: black;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.Education-Container .Education-Card-Container .Education-Card .School-Link figure {
  padding-top: 15px;
  width: 100%;
  height: 60%;
  opacity: 1;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  margin: 0%;
}

.Education-Container .Education-Card-Container .Education-Card .School-Link figure img {
  width: 90%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 25px;
}

.Education-Container .Education-Card-Container .Education-Card .School-Link h4, .Education-Container .Education-Card-Container .Education-Card .School-Link h3 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  text-align: center;
  margin-bottom: 0%;
}

.Education-Container .Education-Card-Container .Education-Card .School-Link h4 {
  margin-bottom: 10px;
}

.Education-Container .Education-Card-Container .School-Link:hover {
  background-color: rgba(133, 133, 133, 0.26);
}

.Experience-Container {
  position: relative;
  z-index: 5;
  min-height: 100vh;
  background-color: white;
  padding-bottom: 30px;
}

.Experience-Container .Section-Title {
  position: absolute;
  right: 0px;
  background-color: #91c8f5;
  -webkit-box-shadow: 3px 3px lightgray;
          box-shadow: 3px 3px lightgray;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.Experience-Container .Section-Title h1 {
  color: black;
  margin-right: 10px;
}

.Experience-Container .Experience-Card-Container {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.Experience-Container .Experience-Card-Container .Experience-Card:nth-child(4n+1) {
  background-color: #91c8f5;
  border-radius: 0px 25px 25px 0px;
}

.Experience-Container .Experience-Card-Container .Experience-Card:nth-child(4n) {
  background-color: #91c8f5;
  border-radius: 25px 0px 0px 25px;
}

.Experience-Container .Experience-Card-Container .Experience-Card:nth-child(2n) {
  border-radius: 25px 0px 0px 25px;
}

.Experience-Container .Experience-Card-Container .Experience-Card:nth-child(2n+1) {
  border-radius: 0px 25px 25px 0px;
}

.Experience-Container .Experience-Card-Container .Experience-Card {
  -ms-flex-preferred-size: 49.5%;
      flex-basis: 49.5%;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 15px 5px 15px #a1a1a1;
          box-shadow: 15px 5px 15px #a1a1a1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Experience-Container .Experience-Card-Container .Experience-Card figure {
  margin: 0%;
  height: 100%;
  width: 100%;
  -ms-flex-preferred-size: 35%;
      flex-basis: 35%;
  margin-left: 10px;
}

.Experience-Container .Experience-Card-Container .Experience-Card figure img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.Experience-Container .Experience-Card-Container .Experience-Card .Experience-Details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: 65%;
      flex-basis: 65%;
}

.Experience-Container .Experience-Card-Container .Experience-Card .Experience-Details h1, .Experience-Container .Experience-Card-Container .Experience-Card .Experience-Details h2 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  text-align: start;
  margin: 0%;
  margin-left: 50px;
}

.Experience-Container .Experience-Card-Container .Experience-Card .Experience-Details h1 {
  font-size: 25px;
  color: black;
}

.Experience-Container .Experience-Card-Container .Experience-Card .Experience-Details h2 {
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .Experience-Container .Experience-Card-Container .Experience-Card {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-radius: 0px 0px 0px 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .Experience-Container .Experience-Card-Container .Experience-Card {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    border-radius: 0px 0px 0px 0px !important;
  }
  .Experience-Container .Experience-Card-Container .Experience-Card figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 100px;
  }
}

.About-Container {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  background-color: white;
  padding-bottom: 100px;
}

.About-Container .About-Title-Container {
  margin-top: 50px;
  position: absolute;
  left: 0px;
  background-color: #91c8f5;
  -webkit-box-shadow: 3px 3px lightgray;
          box-shadow: 3px 3px lightgray;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.About-Container .About-Title-Container .About-Title {
  margin-left: 10px;
  color: black;
}

.About-Container .About-Content-Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 150px;
}

.About-Container .About-Content-Container .Photo-Container {
  margin: 30px;
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-height: 950px;
}

.About-Container .About-Content-Container .Photo-Container img {
  width: 100%;
  height: 100%;
}

.About-Container .About-Content-Container .About-Content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 100px;
  margin-right: 100px;
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  padding-top: 60px;
}

.About-Container .About-Content-Container .About-Content p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: start;
}

@media only screen and (max-width: 1400px) {
  .About-Container .About-Content-Container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding-top: 150px;
  }
  .About-Container .About-Content-Container .About-Content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin-left: 0%;
  }
  .About-Container .About-Content-Container .About-Content p {
    font-size: 20px;
    text-align: start;
    margin-right: 10px;
  }
}

.Footer-Container {
  width: 100%;
  height: 100px;
  background-color: #91c8f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Footer-Container .Footer-Navigation {
  padding-left: 150px;
}

.Footer-Container .Footer-Navigation .Navigation-Item .Navigation-Link {
  text-decoration: none;
  color: white;
  font-size: 25px;
}

.Footer-Container .Footer-Navigation .Navigation-Item .Navigation-Link:hover {
  color: black;
  border-bottom: 1px solid #0099ff;
}

.App {
  font-family: 'Manrope';
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  overflow: hidden;
}

.App h1 {
  color: aliceblue;
  margin: 0%;
}

.Landing-Banner{
    display: flex;
    width: 100%;
    height: 90vh;
    .Box-Left{
        width: 40%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        .Box-Left-Top {
            background-color: #91c8f5;
        }
        .Box-Left-Bottom{
            background-color: #91c8f5;
            z-index: 10;
        }
    }
    .Box-Right{
        width: 60%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        .Box-Right-Top {
            background-color: #ffffff;
        }
        .Box-Right-Bottom{
            background-color: #ffffff;
            z-index: 10;
        }
    }
    .Landing-Title{
        color: black;
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        h1,h2{
            color: black;
        }
        h1{
            font-size: 70px;
            margin-left: 25px;
        }
        h2{
            flex-basis: 100%;
            font-size: 35px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 500px) {
    .Landing-Banner{
        .Landing-Title{
            color: black;
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            h1,h2{
                color: rgb(0, 0, 0);
            }
            h1{
                font-size: 50px;
                margin-left: 25px;
            }
            h2{
                flex-basis: 100%;
                font-size: 35px;
                text-align: center;
            }
        }
    }
}
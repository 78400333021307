.About-Container{
    position: relative;
    z-index: 10;
    min-height: 100vh;
    background-color: white;
    padding-bottom: 100px;
    .About-Title-Container{
        margin-top: 50px;
        position: absolute;
        left: 0px;
        background-color: #91c8f5;
        box-shadow: 3px 3px rgb(211, 211, 211);
        width: 200px;
        display: flex;
        justify-content: start;
        .About-Title{
            margin-left: 10px;
            color: black;
        }
    }
    .About-Content-Container{
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 150px;
        .Photo-Container{
            margin: 30px;
            flex-basis: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 950px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .About-Content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;
            margin-left: 100px;
            margin-right: 100px;
            flex-basis: 40%;
            padding-top: 60px;
            p{
                margin-top: 30px;
                margin-bottom: 30px;
                font-size: 20px;
                text-align: start;
            }
        }
    }
    
}


@media only screen and (max-width: 1400px) {
    .About-Container{
        .About-Content-Container{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            padding-top: 150px;
            .About-Content{
                display: flex;
                flex-wrap: wrap;
                flex-basis: 100%;
                margin-left: 0%;
                p{
                    font-size: 20px;
                    text-align: start;
                    margin-right: 10px;
                }
            }
        }     
    }
}